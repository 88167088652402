<template>
  <div>
    <div class="staff-cont">
      <el-checkbox-group v-model="checkedCities">
        <el-checkbox v-for="(role,key) in cities" :label="role.id" :key="key">{{ role.roleCode }}</el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import { requestForm } from '@/assets/js/http.js'
const roleOptions = ''
export default {
  name: 'EmployeesRoleBody',
  props: ['tabData'],
  data () {
    return {
      newsTabData: '',
      checkedCities: [],
      cities: roleOptions
    }
  },
  created () {
    this.newsTabData = this.tabData
    this.getRole()
  },
  mounted () {

  },
  methods: {
    // 获取角色权限数据
    getRole () {
      const that = this
      requestForm('/api/system/user/getUserRoleByUserId/' + this.newsTabData.id, 'POST').then((res) => {
        if (res.code === '200') {
          this.cities = res.data
          this.cities.forEach(function (item) {
            if (item.isHas) {
              that.checkedCities.push(item.id)
              that.$emit('jurisdiction', that.checkedCities)
            }
          })
        }
      })
    }
  },
  watch: {
    checkedCities () {
      var newsTableDtat = []
      for (let i = 0; i < this.checkedCities.length; i++) {
        for (let j = 0; j < this.cities.length; j++) {
          if (this.checkedCities[i] === this.cities[j].id) {
            newsTableDtat.push(this.cities[j])
          }
        }
      }
      this.$emit('jurisdiction', newsTableDtat)
    },
    tabData () {
      this.newsTabData = this.tabData
    }
  }
}
</script>
<style scoped>
  .roleTab{
    background-color: rgba(68, 135, 250, 0.2);
    margin-top: 10px;
    padding: 10px 20px;
  }
  .staff-code{
    width: 200px;
    float: left;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }
  .staff-name{
    width: 200px;
    float: right;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }
  .staff-cont{
    padding: 20px;
  }
</style>
